import backendClient from "../clients/backend";
import type { AppUser, BeResponse } from "../types/general";
import { isAxiosError } from "axios";

const createLoginUser = async (appUser: AppUser, authToken: string): Promise<BeResponse> => {
    try {
        const apiResponse = await backendClient.post('/auth/', { ...appUser, auth: authToken });
        const response: BeResponse = {
            publicTitle: "Usuario creado correctamente.",
            publicMsg: "Ya puede comenzar una sucesión.",
            code: apiResponse.status || '201',
            data: apiResponse.data
        }
        return response
    } catch (error) {
        let err: BeResponse;
        if (isAxiosError(error)) {
            err = {
                publicTitle: "Error al intentar crear su usuario.",
                publicMsg: "Intentalo nuevamente. Si el error persiste, contacte a soporte técnico.",
                code: error.code,
                internalMsg: error.response?.data
            }
            return Promise.reject(err);
        }
        err = {
            publicTitle: "Error al intentar crear su usuario.",
            publicMsg: " Intentalo nuevamente. Si el error persiste, contacte a soporte técnico.",
            internalMsg: error
        }
        return Promise.reject(err);
    }
}

const appUserService = {
    createLoginUser
}
export default appUserService