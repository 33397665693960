import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import personService from "../../../service/succession";
import { BeResponse } from "../../../types/general";
import { Succession } from "../../../types/person";
import { useToast } from "../../toastContext/toastContext";
import Children from "./children";
import Deceased from "./deceased";
import FamilyTreeState from "./familyTreeState";
import Grandchildren from "./grandchildren";
import Parents from "./parents";
import Spouse from "./spouse";

const Stepper = () => {
  const navigate = useNavigate();
  const { addToast } = useToast();
  const [familyTree, setFamilyTree] = useState<Succession>({
    name: "",
    lastname: "",
    state: "",
    city: "",
    street: "",
    dateOfBirth: "",
    dateOfDeath: "",
    dni: "",
    children: [],
    parents: [],
    siblings: [],
    enabled: true,
    mainDeseased: true,
    type: "inheritance",
  });
  const [stepperProgress, setStepperProgress] = useState<number>(0);
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [backFlag, setBackFlag] = useState<boolean>(false);
  const [stepperButtonEnabled, setStepperButtonEnabled] =
    useState<boolean>(false);

  const restartFamilyTree = () => {
    setFamilyTree({
      name: "",
      lastname: "",
      state: "",
      city: "",
      street: "",
      dateOfBirth: "",
      dateOfDeath: "",
      dni: "",
      children: [],
      parents: [],
      siblings: [],
      enabled: true,
      mainDeseased: true,
      type: "inheritance",
    });
    setCurrentStep(1);
  };
  const handleCurrentStep = (step?: number) => {
    if (step && step < currentStep) {
      setBackFlag(true);
    } else {
      setBackFlag(false);
    }
    setCurrentStep(step ?? currentStep + 1);
  };

  const check = {
    kidsInherit: (): boolean =>
      !!familyTree.children?.some((kid) => kid.enabled && !kid.dateOfDeath),
    grandKidsInherit: (): boolean =>
      !!familyTree.children?.some(
        (kid) =>
          kid.enabled &&
          kid.dateOfDeath &&
          kid.children?.some(
            (grandkid) => grandkid.enabled && !grandkid.dateOfDeath
          )
      ),
  };

  useEffect(() => {
    const handleProgressBar = (forcedProgress?: 1 | 2 | 3 | 4 | 5 | 6) => {
      const progress = forcedProgress ?? currentStep / 6;
      setStepperProgress(progress);
    };
    const handleProcess = () => {
      handleProgressBar();
      switch (true) {
        case currentStep === 1 &&
          !!familyTree.name?.length &&
          !!familyTree.lastname?.length:
          // Deceased data entry
          setStepperButtonEnabled(true);
          break;
        case currentStep === 2 &&
          !!familyTree.name?.length &&
          !!familyTree.lastname?.length:
          // Children and spouse data entry
          console.log("Children and spouse data entry");
          setStepperButtonEnabled(true);
          break;
        case currentStep === 3:
          // I now question if grandkids should inherit, for which we need dead kids enabled
          if (
            familyTree.children?.some((kid) => kid.enabled && kid.dateOfDeath)
          ) {
            setStepperButtonEnabled(true);
          } else {
            // Check if I'm not willingly going back.
            if (!backFlag) {
              // Else, we move to the next step
              setCurrentStep(4);
            } else {
              setCurrentStep(2);
            }
          }
          break;
        case currentStep === 4:
          // I now question if parents should inherit, for which we need no kids nor grandkids nor spouse to inherit
          if (
            !check.kidsInherit() &&
            !check.grandKidsInherit() &&
            !familyTree.spouse
          ) {
            setStepperButtonEnabled(true);
          } else {
            //Finished calculating family tree. Get confirmation and save.
            saveFamilyTree();
            break;
          }
          break;
        case currentStep === 5:
          // I now question if sibling should inherit, for which we need no kids, grandkids, spouse or parents to inherit
          if (
            !check.kidsInherit() &&
            !check.grandKidsInherit() &&
            !familyTree.spouse &&
            !familyTree.parents?.some(
              (parent) => !parent.dateOfDeath && parent.enabled
            )
          ) {
            setStepperButtonEnabled(true);
          } else {
            //Finished calculating family tree. Get confirmation and save.
            saveFamilyTree();
            break;
          }
          break;
        case currentStep === 6:
          //This shouldn't happen - Finished calculating family tree. Get confirmation and save.
          saveFamilyTree();
          break;
        default:
          setStepperButtonEnabled(false);
      }
    };
    handleProcess();
  }, [familyTree, currentStep]);

  const handleFamilyTree = (e: React.ChangeEvent<HTMLInputElement>) => {
    const property = e.target.name;
    const value = e.target.value;
    setFamilyTree({
      ...familyTree,
      [property]: value,
    });
  };

  const saveFamilyTree = async () => {
    try {
      console.log("🚀 ~ saving familyTree:", familyTree);
      const user = localStorage.getItem("user");
      if (!user) {
        navigate("/login");
        throw {
          publicTitle: "Debe iniciar sesión",
          publicMsg: "Para continuar debe iniciar sesión nuevamente.",
        }; //todo check if this throw actually executes ha
      }
      console.log("About to post");
      await personService.postFamilyTree(familyTree, JSON.parse(user));
      addToast(
        "Para continuar, debemos cargar la documentación requerida por los jueces.",
        "Sucesión creada exitosamente.",
        "success"
      );
      navigate("/dashboard");
      //todo maybe navigate to user dashboard or succession details.
    } catch (error) {
      const { publicTitle, publicMsg } = error as BeResponse;
      addToast(publicTitle, publicMsg, "warning");
    }
  };

  return (
    <div style={{ marginTop: "5em" }} className="mx-5 py-1">
      <div id="title" className="mb-3">
        <h1>Iniciar nueva sucesión</h1>
        <FamilyTreeState familyTree={familyTree} />
        <div className="progress" style={{ height: "20px" }}>
          <div
            className="progress-bar"
            role="progressbar"
            style={{ width: `${stepperProgress * 100}%` }}
            aria-valuenow={stepperProgress * 100}
            aria-valuemin={0}
            aria-valuemax={100}
          >
            {Math.round(stepperProgress * 100)}%
          </div>
        </div>
        <p>
          Para empezar, necesitas contarnos un poco sobre la persona fallecida:
        </p>
      </div>

      {/******   STEP 1  - deceased *****/}
      {currentStep === 1 && (
        <Deceased handleFamilyTree={handleFamilyTree} familyTree={familyTree} />
      )}

      {/******   STEP 2  - spouse and children*****/}
      {currentStep === 2 && familyTree.name && familyTree.lastname && (
        <>
          <Spouse
            id="spouse"
            familyTree={familyTree}
            setFamilyTree={setFamilyTree}
          />
          <Children familyTree={familyTree} setFamilyTree={setFamilyTree} />
        </>
      )}

      {/******   STEP 3  - Grandchildren *****/}
      {currentStep === 3 &&
        !!familyTree.children?.length &&
        familyTree.children?.some(
          (child) => child.dateOfDeath && child.enabled
        ) && (
          <Grandchildren
            familyTree={familyTree}
            setFamilyTree={setFamilyTree}
          />
        )}

      {/******   STEP 4  - posibly: parents *****/}
      {currentStep === 4 &&
        ((!familyTree.children?.length && !familyTree.spouse) ||
          // Has no enabled alive child
          familyTree.children?.every(
            (child) =>
              !child.enabled ||
              (child.enabled &&
                child.dateOfDeath &&
                // and also, has no grandkids
                (!child.children?.length ||
                  child.children.every((grandkid) => grandkid.dateOfDeath)))
          )) && (
          <Parents familyTree={familyTree} setFamilyTree={setFamilyTree} />
        )}

      {/******   STEP 5  - posibly: siblings *****/}
      {/*  <Siblings /> */}

      <div id="stepperButtons" className="my-2 d-flex justify-content-between">
        <button
          type="button"
          className="btn btn-outline-danger"
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
        >
          Reiniciar árbol genealógico
        </button>
        <div id="stepsController">
          <button
            disabled={currentStep === 1}
            onClick={() => handleCurrentStep(currentStep - 1)}
            className="btn btn-outline-primary mx-2"
          >
            Volver
          </button>
          <button
            disabled={!stepperButtonEnabled}
            onClick={() => handleCurrentStep()}
            className="btn btn-outline-primary mx-2"
          >
            Continuar
          </button>
        </div>
      </div>

      <div
        className="modal fade"
        id="exampleModal"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Reiniciar árbol genealógico{" "}
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <p>
                <strong>Atención! </strong>
                Si reinicias el árbol genealógico, borras todo el avance de
                carga de datos que hiciste hasta ahora. Estas seguro/a de
                hacerlo?
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-success"
                data-bs-dismiss="modal"
              >
                Continuar árbol
              </button>
              <button
                type="button"
                onClick={() => restartFamilyTree()}
                className="btn btn-danger"
                data-bs-dismiss="modal"
              >
                Reiniciar árbol
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Stepper;
