import { isAxiosError } from "axios";
import backendClient from "../clients/backend";
import { AppUser, BeResponse } from "../types/general";
import { Succession } from "../types/person";

const postFamilyTree = async (familyTree: Succession, appUser: AppUser): Promise<BeResponse> => {
    try {
        const token = localStorage.getItem('token')
        if (!token) throw { code: 401 }
        const apiResponse = await backendClient.post('/succession/', { successions: familyTree, appUser: { ...appUser } }, { headers: { 'authorization': token } });
        const response: BeResponse = {
            publicTitle: "Sucesión creada correctamente.",
            publicMsg: "Para continuar, debemos cargar la documentación requerida por los jueces.",
            code: apiResponse.status || '201'
        }
        return response
    } catch (error) {
        let err: BeResponse;
        if (isAxiosError(error)) {
            err = {
                publicTitle: "Error al intentar crear el árbol genealógico.",
                publicMsg: " Intentalo nuevamente. Si el error persiste, contacte a soporte técnico.",
                code: error.code,
                internalMsg: error.response?.data
            }
            return Promise.reject(err);
        }
        err = {
            publicTitle: "Error al intentar crear el árbol genealógico.",
            publicMsg: " Intentalo nuevamente. Si el error persiste, contacte a soporte técnico.",
            internalMsg: error
        }
        return Promise.reject(err);
    }
}

const fetchAllAppUsersSuccessions = async (): Promise<BeResponse> => {
    try {
        const token = localStorage.getItem('token');
        if (!token) throw { code: 401, publicMsg: "Debe iniciar sesión nuevamente.", publicTitle: "Error con su sesión." };

        const apiResponse = await backendClient.get(`/auth/successions`, {
            headers: { 'authorization': `Bearer ${token}` }
        });

        const response: BeResponse = {
            publicTitle: "Sucesiones obtenidas correctamente.",
            publicMsg: "Las sucesiones del usuario se han cargado.",
            data: apiResponse.data,
            code: apiResponse.status || '200'
        };
        return response;
    } catch (error) {
        let err: BeResponse;
        if (isAxiosError(error)) {
            err = {
                publicTitle: "Error al intentar obtener las sucesiones.",
                publicMsg: "Inténtalo nuevamente. Si el error persiste, contacte a soporte técnico.",
                code: error.code,
                internalMsg: error.response?.data
            };
            return Promise.reject(err);
        }
        err = {
            publicTitle: "Error al intentar obtener las sucesiones.",
            publicMsg: "Inténtalo nuevamente. Si el error persiste, contacte a soporte técnico.",
            internalMsg: error
        };
        return Promise.reject(err);
    }
};

const personService = {
    postFamilyTree,
    fetchAllAppUsersSuccessions
}
export default personService