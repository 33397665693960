import React, { useEffect, useState } from "react";
import { Person } from "../../../types/person";

interface Props {
  familyTree: Person;
}

const FamilyTreeState = ({ familyTree }: Props) => {
  const [familyTreeHasContent, setFamilyTreeHasContent] =
    useState<boolean>(false);
  useEffect(() => {
    const hasContent: boolean = !!(
      (!!familyTree.children?.length &&
        familyTree.children?.some((child) => child.enabled)) ||
      familyTree.spouse ||
      familyTree.children?.some(
        (child) =>
          child.enabled &&
          child.dateOfDeath &&
          child.children?.length &&
          child.children.some(
            (grandchild) => grandchild.enabled && !grandchild.dateOfDeath
          )
      )
    );
    setFamilyTreeHasContent(hasContent);
  }, [familyTree]);

  return (
    <div className="container">
      <div className="row">
        {familyTreeHasContent && (
          <>
            <h5>
              <span className="text-capitalize">{familyTree.name}</span> tiene:{" "}
            </h5>
            {<p>Su conyuge que le hereda.</p>}
            {!!familyTree.children?.length && (
              <p>
                {
                  familyTree.children.filter((child) => !child.dateOfDeath)
                    .length
                }
                Hijos/as que le heredan.
              </p>
            )}
            {!!familyTree.children?.length && (
              <p>
                {
                  familyTree.children.filter(
                    (child) => child.enabled && !child.dateOfDeath
                  ).length
                }{" "}
                Hijos/as que le heredan.
              </p>
            )}
            {!!familyTree.children?.some(
              (child) =>
                child.enabled && child.dateOfDeath && !!child.children?.length
            ) && (
              <p>
                {
                  familyTree.children?.filter(
                    (child) =>
                      child.enabled &&
                      child.dateOfDeath &&
                      !!child.children?.length
                  ).length
                }{" "}
                Nietos que le heredan.
              </p>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default FamilyTreeState;
