import React, { useState } from "react";
import statesData from "./argentinaStateCity.json";
import { Person, Succession } from "../../../types/person";

interface IProps {
  familyTree: Person;
  setFamilyTree: React.Dispatch<React.SetStateAction<Succession>>;
}

const Children = ({ familyTree, setFamilyTree }: IProps) => {
  const defaultEnableChildren =
    process.env.REACT_APP_NODE_ENV === "development" ? true : false;
  const [enableChildren, setEnableChildren] = useState<boolean>(
    defaultEnableChildren
  );
  const [availableCities, setAvailableCities] = useState<string[]>([]);

  const states: string[] = [
    ...new Set(
      statesData.departamentos.map(
        (departamento) => departamento.provincia.nombre
      )
    ),
  ];

  const handleChildrenAmmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    const requiredAmmount = Number(e.target.value);
    const childArray: Person[] = familyTree.children
      ? [...familyTree.children]
      : [];
    const existingAmmount = childArray.length;
    const existingEnabledAmmount = childArray.filter(
      (child) => child.enabled === true
    ).length;
    const isADisabledChild = existingAmmount > existingEnabledAmmount;

    const handleSetChildArray = (childArray: Person[]) => {
      setFamilyTree({
        ...familyTree,
        children: [...childArray],
      });
    };
    let childToEnable, childToDisable;
    switch (true) {
      case requiredAmmount === 0:
        // Disable all children
        handleSetChildArray(
          childArray.map((child) => ({ ...child, enabled: false }))
        );
        break;
      case existingAmmount === 0 ||
        (requiredAmmount > existingAmmount && !isADisabledChild):
        // Create a new blank child
        childArray.push({ dateOfDeath: undefined, enabled: true });
        handleSetChildArray(childArray);
        break;
      case requiredAmmount > existingEnabledAmmount && isADisabledChild:
        // Simply enable first disabled child
        childToEnable = childArray.findIndex(
          (child) => child.enabled === false
        );
        childArray[childToEnable].enabled = true;
        handleSetChildArray(childArray);
        break;
      case requiredAmmount < existingEnabledAmmount:
        // Disable last currently enabled child
        childToDisable =
          childArray.filter((child) => child.enabled === true).length - 1;
        childArray[childToDisable].enabled = false;
        handleSetChildArray(childArray);
        break;
    }
  };
  const handleDateOfDeath = (
    e: React.ChangeEvent<HTMLInputElement>,
    childNumber: number
  ) => {
    const familyTreeCopy = { ...familyTree };
    if (familyTreeCopy.children && familyTreeCopy.children[childNumber]) {
      // If the value sent is not a date, then we set the dateOfDeath to undefined
      familyTreeCopy.children[childNumber].dateOfDeath = isNaN(
        Date.parse(e.target.value)
      )
        ? undefined
        : e.target.value;

      setFamilyTree({ ...familyTreeCopy });
    }
  };
  const handleChildData = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    childIndex: number
  ) => {
    const property = e.target.name as keyof Person;

    // Literally this value could be any type
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let value: any = e.target.value;

    // Dynamically handle type conversions if needed
    if (property === "enabled" || property === "mainDeseased") {
      value = e.target.value === "true"; // Assuming values are passed as "true" or "false" strings
    } else if (property === "dateOfBirth" || property === "dateOfDeath") {
      // Date might need to be undefined if it's an invalid date or empty
      value = value ? new Date(value).toISOString() : undefined;
    }

    const familyTreeCopy = { ...familyTree };
    if (familyTreeCopy.children && familyTreeCopy.children[childIndex]) {
      familyTreeCopy.children[childIndex][property] = value;
    }
    setFamilyTree({
      ...familyTreeCopy,
    });
    if (property === "state") {
      setAvailableCities(
        statesData.departamentos
          .filter((departamentos) => {
            if (
              departamentos.provincia.nombre === value &&
              departamentos.provincia.nombre !==
                "Ciudad Autónoma de Buenos Aires"
            ) {
              return departamentos.nombre;
            }
          })
          .map((departamentos) => departamentos.nombre)
      );
    }
    console.log("🚀 ~ familyTree:", familyTree);
  };

  return (
    <>
      <div style={{ minHeight: "120px" }}>
        <div className="card card-body">
          <div className="mb-3">
            <h4>3) Hijos</h4>
            <p>El fallecido tuvo hijos? cuantos?</p>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                defaultChecked={enableChildren}
                type="checkbox"
                onChange={() => {
                  setEnableChildren(!enableChildren);
                }}
                role="switch"
                name="enableChildren"
                id="enableChildren"
              />
              <label className="form-check-label" htmlFor="spouseIsAlive">
                El fallecido si tuvo hijos.
              </label>
            </div>
            <label htmlFor="childrenAmmount" className="form-label">
              Cantidad de hijos:
            </label>
            <input
              type="number"
              min={0}
              max={15}
              placeholder="0"
              className="form-control"
              onChange={(e) => handleChildrenAmmount(e)}
              id="childrenAmmount"
              name="childrenAmmount"
              aria-describedby="Cantidad de hijos del fallecido"
            />
          </div>
        </div>
        {enableChildren &&
          familyTree.children
            ?.filter((child: Person) => child.enabled)
            .map((child: Person, index: number) => {
              return (
                <div
                  className="card card-body"
                  key={`childN${index}`}
                  id={`childN${index}`}
                >
                  <div className="mb-3">
                    <h4>Hijo o hija N° {index + 1} </h4>

                    <label htmlFor="name" className="form-label">
                      Nombre
                    </label>
                    <input
                      onChange={(e) => {
                        handleChildData(e, index);
                      }}
                      type="text"
                      className="form-control"
                      value={familyTree.children?.[index].name || ""}
                      id={"name"}
                      name={"name"}
                      aria-describedby="Nombre del fallecido"
                    />
                    <label htmlFor="lastname" className="form-label">
                      Apellido
                    </label>
                    <input
                      onChange={(e) => {
                        handleChildData(e, index);
                      }}
                      type="text"
                      className="form-control"
                      value={familyTree.children?.[index].lastname || ""}
                      id={"lastname"}
                      name={"lastname"}
                      aria-describedby="Apellido del fallecido"
                    />

                    <label htmlFor="name" className="form-label">
                      DNI
                    </label>
                    <input
                      onChange={(e) => {
                        handleChildData(e, index);
                      }}
                      type="text"
                      className="form-control"
                      value={familyTree.children?.[index].dni || ""}
                      id={"dni"}
                      name={"dni"}
                      aria-describedby="Número de Dni del hijo/a"
                    />

                    <h6>Domicilio actual:</h6>
                    <p>
                      Nos referimos al lugar donde usualmente vive, es decir,
                      donde tiene su hogar.
                    </p>

                    <label htmlFor="state" className="form-label">
                      Provincia
                    </label>
                    <select
                      className="form-control"
                      onChange={(e) => {
                        handleChildData(e, index);
                      }}
                      name="state"
                      id="state"
                    >
                      {states.map((state, index) => (
                        <option key={index} value={state}>
                          {state}
                        </option>
                      ))}
                    </select>

                    <label htmlFor="city" className="form-label">
                      Ciudad
                    </label>
                    <select
                      className="form-control"
                      onChange={(e) => {
                        handleChildData(e, index);
                      }}
                      name="city"
                      id="city"
                    >
                      {availableCities.map((city, index) => (
                        <option key={index} value={city}>
                          {city}
                        </option>
                      ))}
                    </select>

                    <label htmlFor="street" className="form-label">
                      Calle, número y departamento
                    </label>
                    <input
                      onChange={(e) => {
                        handleChildData(e, index);
                      }}
                      type="text"
                      className="form-control"
                      value={familyTree.children?.[index].street || ""}
                      id={"street"}
                      name={"street"}
                      aria-describedby="Ej: Balcarce 670, 9A"
                    />

                    <p>
                      Si este hijo/a ya falleció, indique fecha de
                      fallecimiento.
                    </p>
                    <label htmlFor="name" className="form-label">
                      <strong>Opcional</strong> - Fecha de fallecimiento:
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      value={child.dateOfDeath}
                      onChange={(e) => handleDateOfDeath(e, index)}
                      id="deathDate"
                      name="deathDate"
                      aria-describedby="Fecha de fallecimiento del hijo"
                    />
                  </div>
                </div>
              );
            })}
      </div>
    </>
  );
};
export default Children;
