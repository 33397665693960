import React, { useEffect, useState } from "react";
import statesData from "./argentinaStateCity.json";
import { Person, Succession } from "../../../types/person";

interface IProps {
  familyTree: Person;
  setFamilyTree: React.Dispatch<React.SetStateAction<Succession>>;
}

const Grandchildren = ({ familyTree, setFamilyTree }: IProps) => {
  const [availableCities, setAvailableCities] = useState<string[]>([]);
  const [deadChildren, setDeadChildren] = useState<boolean>();
  const [childrenWithGrandchildren, setChildrenWithGrandchildren] = useState<
    number[]
  >([]);
  const states: string[] = [
    ...new Set(
      statesData.departamentos.map(
        (departamento) => departamento.provincia.nombre
      )
    ),
  ];

  const handleGrandchildrenAmmount = (
    e: React.ChangeEvent<HTMLInputElement>,
    childIndex: number
  ) => {
    const requiredAmmount = Number(e.target.value);
    const children = familyTree.children || [];
    const child = children[childIndex];
    const grandchildArray: Person[] = child.children ? [...child.children] : [];
    const existingAmmount = grandchildArray.length;
    const existingEnabledAmmount = grandchildArray.filter(
      (grandchild) => grandchild.enabled === true
    ).length;
    const isADisabledChild = existingAmmount > existingEnabledAmmount;

    const handleSetGrandchildArray = (
      grandchildArray: Person[],
      childIndex: number
    ) => {
      const familyTreeCopy = { ...familyTree };
      if (familyTreeCopy.children) {
        familyTreeCopy.children[childIndex].children = [...grandchildArray];
        setFamilyTree(familyTreeCopy);
      }
    };
    let childToEnable, childToDisable;
    switch (true) {
      case requiredAmmount === 0:
        // Disable all existing children
        handleSetGrandchildArray(
          grandchildArray.map((grandchild) => ({
            ...grandchild,
            enabled: false,
          })),
          childIndex
        );
        break;
      case existingAmmount === 0 ||
        (requiredAmmount > existingAmmount && !isADisabledChild):
        // Create a new blank child
        grandchildArray.push({ dateOfDeath: undefined, enabled: true });
        handleSetGrandchildArray(grandchildArray, childIndex);
        break;
      case requiredAmmount > existingEnabledAmmount && isADisabledChild:
        // Simply enable first disabled child
        childToEnable = grandchildArray.findIndex(
          (grandchild) => grandchild.enabled === false
        );
        grandchildArray[childToEnable].enabled = true;
        handleSetGrandchildArray(grandchildArray, childIndex);
        break;
      case requiredAmmount < existingEnabledAmmount:
        // Disable last currently enabled child
        childToDisable =
          grandchildArray.filter((grandchild) => grandchild.enabled === true)
            .length - 1;
        grandchildArray[childToDisable].enabled = false;
        handleSetGrandchildArray(grandchildArray, childIndex);
        break;
    }
  };
  const handleDateOfDeath = (
    e: React.ChangeEvent<HTMLInputElement>,
    childIndex: number,
    grandchildIndex: number
  ) => {
    const familyTreeCopy = { ...familyTree };
    const child = familyTreeCopy.children?.[childIndex];
    const grandchild = child?.children?.[grandchildIndex];

    if (grandchild) {
      // If the value sent is not a date, then we set the dateOfDeath to undefined
      grandchild.dateOfDeath = isNaN(Date.parse(e.target.value))
        ? undefined
        : e.target.value;
      setFamilyTree(familyTreeCopy);
    }
  };
  const handleGrandchildData = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    childIndex: number,
    grandchildIndex: number
  ) => {
    const property = e.target.name as keyof Person;
    const value = e.target.value;
    const familyTreeCopy = { ...familyTree };

    // @ts-expect-error  // We know these values exists because of logics. Fixing this is more expensive than just ignoring it.
    familyTreeCopy.children[childIndex].children[grandchildIndex][property] =
      value;

    setFamilyTree({
      ...familyTreeCopy,
    });
    if (property === "state") {
      setAvailableCities(
        statesData.departamentos
          .filter((departamentos) => {
            if (
              departamentos.provincia.nombre === value &&
              departamentos.provincia.nombre !==
                "Ciudad Autónoma de Buenos Aires"
            ) {
              return departamentos.nombre;
            }
          })
          .map((departamentos) => departamentos.nombre)
      );
    }
  };
  const handleEnableGrandchildren = (
    childIndex: number,
    e: React.ChangeEvent<HTMLInputElement>,
    enable?: boolean
  ): void => {
    if (!e.target.checked || enable === true) {
      const newChildrenWithGrandchildren = [
        ...new Set([...childrenWithGrandchildren, childIndex]),
      ];
      setChildrenWithGrandchildren([...newChildrenWithGrandchildren]);
      return;
    }
    const index = childrenWithGrandchildren.indexOf(childIndex);
    const childrenWithGrandchildrenCopy = [...childrenWithGrandchildren];
    if (index > -1) {
      // only splice array when item is found
      childrenWithGrandchildrenCopy.splice(index, 1);
    }
    setChildrenWithGrandchildren([...childrenWithGrandchildrenCopy]);
  };

  useEffect(() => {
    setDeadChildren(
      familyTree.children &&
        familyTree.children.some(
          (child) => !!child.dateOfDeath && child.enabled
        )
    );
    console.log("🚀 ~ Final FamilyTree:", familyTree);
  }, [familyTree]);

  return (
    <>
      {
        // Let's first check there actually are dead enabled childs.
        deadChildren &&
          // We traverse each dead child
          familyTree.children &&
          familyTree.children
            .filter((child) => !!child.dateOfDeath && child.enabled)
            .map((child: Person, childIndex: number) => {
              return (
                <div
                  key={childIndex}
                  id={`deadChildN${childIndex}`}
                  style={{ minHeight: "120px" }}
                >
                  <div className="card card-body">
                    <div className="mb-3">
                      <h4>
                        4) Nietos (Hijos/as de {child.name}
                        {child.lastname ?? ""})
                      </h4>
                      <p>
                        Si el hijo llamado {child.name} {child.lastname ?? ""}{" "}
                        no tuvo hijos, puede saltear este paso.
                      </p>
                      <label
                        htmlFor="grandchildrenAmmount"
                        className="form-label"
                      >
                        Cantidad de hijos:
                      </label>
                      <input
                        type="number"
                        min={0}
                        max={15}
                        placeholder="0"
                        className="form-control"
                        onChange={(e) => {
                          const hasGrandChild = !!e.target.value;
                          handleGrandchildrenAmmount(e, childIndex);
                          handleEnableGrandchildren(
                            childIndex,
                            e,
                            hasGrandChild
                          );
                        }}
                        id="grandchildrenAmmount"
                        name="grandchildrenAmmount"
                        aria-describedby={`Cantidad de hijos de ${child.name} ${
                          child.lastname ?? ""
                        }`}
                      />
                    </div>
                  </div>
                  {childrenWithGrandchildren.includes(childIndex) &&
                    child.children &&
                    child.children.map(
                      (grandchild: Person, grandchildIndex: number) => {
                        if (grandchild.enabled) {
                          return (
                            <div
                              key={grandchildIndex}
                              className="card card-body"
                              id={`grandchildN${grandchildIndex}`}
                            >
                              <div className="mb-3">
                                <h4>Nieto o Nieta N° {grandchildIndex + 1} </h4>

                                <label htmlFor="name" className="form-label">
                                  Nombre
                                </label>
                                <input
                                  onChange={(e) => {
                                    handleGrandchildData(
                                      e,
                                      childIndex,
                                      grandchildIndex
                                    );
                                  }}
                                  type="text"
                                  className="form-control"
                                  value={
                                    familyTree.children?.[childIndex]
                                      .children?.[grandchildIndex].name || ""
                                  }
                                  id={"name"}
                                  name={"name"}
                                  aria-describedby="Nombre del nieto/a"
                                />
                                <label
                                  htmlFor="lastname"
                                  className="form-label"
                                >
                                  Apellido
                                </label>
                                <input
                                  onChange={(e) => {
                                    handleGrandchildData(
                                      e,
                                      childIndex,
                                      grandchildIndex
                                    );
                                  }}
                                  type="text"
                                  className="form-control"
                                  value={
                                    familyTree.children?.[childIndex]
                                      .children?.[grandchildIndex].lastname ||
                                    ""
                                  }
                                  id={"lastname"}
                                  name={"lastname"}
                                  aria-describedby="Apellido del nieto/a"
                                />

                                <label htmlFor="name" className="form-label">
                                  DNI
                                </label>
                                <input
                                  onChange={(e) => {
                                    handleGrandchildData(
                                      e,
                                      childIndex,
                                      grandchildIndex
                                    );
                                  }}
                                  type="text"
                                  className="form-control"
                                  value={
                                    familyTree.children?.[childIndex]
                                      .children?.[grandchildIndex].dni || ""
                                  }
                                  id={"dni"}
                                  name={"dni"}
                                  aria-describedby="Número de Dni del nieto/a"
                                />

                                <h6>Domicilio actual:</h6>
                                <p>
                                  Nos referimos al lugar donde usualmente vive,
                                  es decir, donde tiene su hogar.
                                </p>

                                <label htmlFor="state" className="form-label">
                                  Provincia
                                </label>
                                <select
                                  className="form-control"
                                  onChange={(e) => {
                                    handleGrandchildData(
                                      e,
                                      childIndex,
                                      grandchildIndex
                                    );
                                  }}
                                  name="state"
                                  id="state"
                                >
                                  {states.map((state, stateIndex) => (
                                    <option key={stateIndex} value={state}>
                                      {state}
                                    </option>
                                  ))}
                                </select>

                                <label htmlFor="city" className="form-label">
                                  Ciudad
                                </label>
                                <select
                                  className="form-control"
                                  onChange={(e) => {
                                    handleGrandchildData(
                                      e,
                                      childIndex,
                                      grandchildIndex
                                    );
                                  }}
                                  name="city"
                                  id="city"
                                >
                                  {availableCities.map((city, cityIndex) => (
                                    <option key={cityIndex} value={city}>
                                      {city}
                                    </option>
                                  ))}
                                </select>

                                <label htmlFor="street" className="form-label">
                                  Calle, número y departamento
                                </label>
                                <input
                                  onChange={(e) => {
                                    handleGrandchildData(
                                      e,
                                      childIndex,
                                      grandchildIndex
                                    );
                                  }}
                                  type="text"
                                  className="form-control"
                                  value={
                                    familyTree.children?.[childIndex]
                                      .children?.[grandchildIndex].street || ""
                                  }
                                  id={"street"}
                                  name={"street"}
                                  aria-describedby="Ej: Balcarce 670, 9A"
                                />

                                <p>
                                  Si este hijo/a ya falleció, indique fecha de
                                  fallecimiento.
                                </p>
                                <label htmlFor="name" className="form-label">
                                  <strong>Opcional</strong> - Fecha de
                                  fallecimiento:
                                </label>
                                <input
                                  type="date"
                                  className="form-control"
                                  value={
                                    child.children?.[grandchildIndex]
                                      .dateOfDeath
                                  }
                                  onChange={(e) =>
                                    handleDateOfDeath(
                                      e,
                                      childIndex,
                                      grandchildIndex
                                    )
                                  }
                                  id="deathDate"
                                  name="deathDate"
                                  aria-describedby="Fecha de fallecimiento del hijo"
                                />
                              </div>
                            </div>
                          );
                        }
                      }
                    )}
                </div>
              );
            })
      }
    </>
  );
};
export default Grandchildren;
