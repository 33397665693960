import React, { useState } from "react";
import statesData from "./argentinaStateCity.json";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Spouse = ({ familyTree, setFamilyTree }: Record<string, any>) => {
  const defaultEnableSpouse =
    process.env.REACT_APP_NODE_ENV === "development"
      ? { isMarried: true, spouseIsAlive: true, isDivorced: false }
      : { isMarried: false, spouseIsAlive: false, isDivorced: true };
  const [enableSpouse, setEnableSpouse] = useState<{
    isMarried: boolean;
    spouseIsAlive: boolean;
    isDivorced: boolean;
  }>(defaultEnableSpouse);
  const [availableCities, setAvailableCities] = useState<string[]>([]);

  const states: string[] = [
    ...new Set(
      statesData.departamentos.map(
        (departamento) => departamento.provincia.nombre
      )
    ),
  ];

  const handleEnableSpouse = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.name;
    const value = e.target.checked;
    setEnableSpouse({ ...enableSpouse, [input]: value });
  };

  const handleSpouse = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const property = e.target.name;
    const value = e.target.value;
    setFamilyTree({
      ...familyTree,
      spouse: {
        ...familyTree.spouse,
        [property]: value,
      },
    });
    if (property === "state") {
      setAvailableCities(
        statesData.departamentos
          .filter((departamentos) => {
            if (
              departamentos.provincia.nombre === value &&
              departamentos.provincia.nombre !==
                "Ciudad Autónoma de Buenos Aires"
            ) {
              return departamentos.nombre;
            }
          })
          .map((departamentos) => departamentos.nombre)
      );
    }
  };

  return (
    <>
      {/******  Spouse *****/}
      <div style={{ minHeight: "120px" }}>
        <div className="card card-body">
          <div className="mb-3">
            <h4>2) Datos del esposo o esposa</h4>
            <p>Para heredar el cónyuge, primero debemos saber:</p>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                defaultChecked={enableSpouse.isMarried}
                type="checkbox"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleEnableSpouse(e);
                }}
                role="switch"
                name="isMarried"
                id="isMarried"
              />
              <label className="form-check-label" htmlFor="isMarried">
                Tiene esposo o esposa.
              </label>
            </div>
            {enableSpouse.isMarried ? (
              <>
                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    defaultChecked={enableSpouse.spouseIsAlive}
                    type="checkbox"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleEnableSpouse(e);
                    }}
                    role="switch"
                    name="spouseIsAlive"
                    id="spouseIsAlive"
                  />
                  <label className="form-check-label" htmlFor="spouseIsAlive">
                    El esposo o esposa sigue vivo.
                  </label>
                </div>
                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    defaultChecked={enableSpouse.isDivorced}
                    type="checkbox"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleEnableSpouse(e);
                    }}
                    role="switch"
                    name="isDivorced"
                    id="isDivorced"
                  />
                  <label className="form-check-label" htmlFor="isDivorced">
                    Están divorciados.
                  </label>
                </div>
              </>
            ) : (
              <div className="alert alert-info" role="alert">
                Si el fallecido nunca se casó, puede seguir con el siguiente
                paso (Paso 3 - Hijos).
              </div>
            )}
            {enableSpouse.isMarried &&
            !enableSpouse.isDivorced &&
            enableSpouse.spouseIsAlive ? (
              <>
                <h5 className="my-3">
                  Si el conyuge sigue vivo y casado, entonces si hereda:
                </h5>
                <label htmlFor="name" className="form-label">
                  Nombre
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={familyTree.spouse?.name}
                  onChange={(e) => handleSpouse(e)}
                  id="name"
                  name="name"
                  aria-describedby="Nombre del fallecido"
                />
                <label htmlFor="lastname" className="form-label">
                  Apellido
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={familyTree.spouse?.lastname}
                  onChange={(e) => handleSpouse(e)}
                  id="lastname"
                  name="lastname"
                  aria-describedby="Apellido del fallecido"
                />

                <label htmlFor="name" className="form-label">
                  DNI
                </label>
                <input
                  onChange={(e) => {
                    handleSpouse(e);
                  }}
                  type="text"
                  className="form-control"
                  value={familyTree.spouse?.dni || ""}
                  id={"dni"}
                  name={"dni"}
                  aria-describedby="Número de Dni del esposo/a"
                />

                <h6>Último domicilio del cónyuge:</h6>
                <p>
                  Nos referimos al lugar donde usualmente vive ésta persona.
                </p>

                <label htmlFor="state" className="form-label">
                  Provincia
                </label>
                <select
                  className="form-control"
                  onChange={(e) => {
                    handleSpouse(e);
                  }}
                  name="state"
                  id="state"
                >
                  {states.map((state, index) => (
                    <option key={index} value={state}>
                      {state}
                    </option>
                  ))}
                </select>

                <label htmlFor="city" className="form-label">
                  Ciudad
                </label>
                <select
                  className="form-control"
                  onChange={(e) => {
                    handleSpouse(e);
                  }}
                  name="city"
                  id="city"
                >
                  {availableCities.map((city, index) => (
                    <option key={index} value={city}>
                      {city}
                    </option>
                  ))}
                </select>

                <label htmlFor="street" className="form-label">
                  Calle, número y departamento
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={familyTree.spouse?.street}
                  onChange={(e) => handleSpouse(e)}
                  id="street"
                  name="street"
                  aria-describedby="Ej: Balcarce 670, 9A"
                />
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default Spouse;
