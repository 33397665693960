import React from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Deseased = ({ familyTree, handleFamilyTree }: Record<string, any>) => {
  return (
    <div style={{ minHeight: "120px" }}>
      <div className="card card-body">
        <div className="mb-3">
          <h4>1) Datos del fallecido</h4>
          <label htmlFor="name" className="form-label">
            Nombre
          </label>
          <input
            type="text"
            className="form-control"
            value={familyTree.name || ""}
            onChange={(e) => handleFamilyTree(e)}
            id="name"
            name="name"
            aria-describedby="Nombre del fallecido"
          />
          <label htmlFor="lastname" className="form-label">
            Apellido
          </label>
          <input
            type="text"
            className="form-control"
            value={familyTree.lastname || ""}
            onChange={(e) => handleFamilyTree(e)}
            id="lastname"
            name="lastname"
            aria-describedby="Apellido del fallecido"
          />
          <h6>Último domicilio del fallecido:</h6>
          <p>
            Nos referimos al lugar donde usualmente vivía el fallecido cuando
            murió, es decir, donde tenía su hogar.
          </p>
          <label htmlFor="state" className="form-label">
            Provincia
          </label>
          <input
            type="text"
            className="form-control"
            value={familyTree.state || ""}
            onChange={(e) => handleFamilyTree(e)}
            id="state"
            name="state"
            aria-describedby="Ej: Tucumán"
          />
          <label htmlFor="city" className="form-label">
            Ciudad
          </label>
          <input
            type="text"
            className="form-control"
            value={familyTree.city || ""}
            onChange={(e) => handleFamilyTree(e)}
            id="city"
            name="city"
            aria-describedby="Ej: San Miguel de Tucumán"
          />
          <label htmlFor="street" className="form-label">
            Calle, número y departamento
          </label>
          <input
            type="text"
            className="form-control"
            value={familyTree.street || ""}
            onChange={(e) => handleFamilyTree(e)}
            id="street"
            name="street"
            aria-describedby="Ej: Balcarce 670, 9A"
          />
        </div>
      </div>
    </div>
  );
};
export default Deseased;
