import React, { createContext, useContext, useState, ReactNode } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamation } from "@fortawesome/free-solid-svg-icons";

interface Toast {
  id: number;
  message: string | undefined;
  color?: string | undefined;
  title?: string | undefined;
}

interface ToastContextType {
  addToast: (
    message: string | undefined,
    color?: string | undefined,
    title?: string | undefined
  ) => void;
}

const ToastContext = createContext<ToastContextType | undefined>(undefined);

export const useToast = () => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error("useToast must be used within a ToastProvider");
  }
  return context;
};

const getColorClass = (color: string) => {
  const colorClassMap: { [key: string]: string } = {
    warning: "bg-warning",
    danger: "bg-danger",
    success: "bg-success",
    info: "bg-info",
  };
  return colorClassMap[color];
};

export const ToastProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [toasts, setToasts] = useState<Toast[]>([]);

  /**
   * Displays a toast notification with a specified message, color, and title.
   * The toast automatically disappears after 4 seconds.
   *
   * @param {string | undefined} message - The message to display in the toast.
   * @param {string | undefined} [title] - Optional. The title of the toast. Defaults to undefined if not provided.
   * @param {string | undefined} [color] - Optional. The color theme of the toast, used to categorize the type of message (e.g., 'success', 'error'). Defaults to undefined if not provided.
   */
  const addToast = (
    message?: string | undefined,
    title?: string | undefined,
    color?: string | undefined
  ) => {
    const id = new Date().getTime();
    setToasts((prevToasts) => [
      ...prevToasts,
      {
        id,
        message:
          message || "Si el error persiste, contacte al soporte técnico.",
        color: color || "info",
        title: title || "Unknown error.",
      },
    ]);
    setTimeout(() => {
      setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
    }, 4000);
  };

  return (
    <ToastContext.Provider value={{ addToast }}>
      {children}
      <div
        aria-live="polite"
        className="mx-1"
        aria-atomic="true"
        style={{
          position: "fixed",
          top: 80,
          right: 0,
          minHeight: "200px",
          zIndex: 999,
        }}
      >
        <div style={{ position: "absolute", top: 0, right: 0 }}>
          {toasts.map((toast) => (
            <div
              key={toast.id}
              className="toast show"
              role="alert"
              aria-live="assertive"
              aria-atomic="true"
            >
              <div className="toast-header">
                <span
                  className={`me-2 my-0 ${getColorClass(
                    toast.color ?? "info"
                  )} rounded p-1 px-2`}
                >
                  <FontAwesomeIcon
                    className="text-light"
                    icon={faExclamation}
                  />
                </span>
                <strong className="mr-auto">{toast.title ?? "Atención"}</strong>
              </div>
              <div className="toast-body">{toast.message}</div>
            </div>
          ))}
        </div>
      </div>
    </ToastContext.Provider>
  );
};
