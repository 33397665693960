import React, { useEffect, useState } from "react";
import { Succession } from "../../../types/person";
import { BeResponse } from "../../../types/general";
import personService from "../../../service/succession";

const Dashboard = () => {
  const [successions, setSuccessions] = useState<Succession[] | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [message, setMessage] = useState<string | null | undefined>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response: BeResponse =
          await personService.fetchAllAppUsersSuccessions();
        if (response.data?.content) {
          setSuccessions(response.data.content.successions);
          setMessage(response.publicMsg);
        } else {
          setError("No se encontraron sucesiones.");
        }
      } catch (err) {
        if (err instanceof Error) {
          setError(err.message);
        } else {
          setError("Error desconocido al obtener las sucesiones.");
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="mt-5 py-3 container-fluid mx-5">
      <h1 className="mt-3">Control de sucesiones</h1>
      {loading && <p>Cargando sucesiones...</p>}
      {error && <p style={{ color: "red" }}>{error}</p>}
      {message && <p style={{ color: "green" }}>{message}</p>}
      {!loading && !error && successions && (
        <div>
          {successions.length === 0 ? (
            <p>No se encontraron sucesiones.</p>
          ) : (
            <ul>
              {successions.map((succession, index) => (
                <li key={index}>{succession.name || "Sin Nombre"}</li>
              ))}
            </ul>
          )}
        </div>
      )}
    </div>
  );
};
export default Dashboard;
