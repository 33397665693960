import React, { useState } from "react";
import statesData from "./argentinaStateCity.json";
import { Person, Succession } from "../../../types/person";

interface IProps {
  familyTree: Person;
  setFamilyTree: React.Dispatch<React.SetStateAction<Succession>>;
}

const Parents = ({ familyTree, setFamilyTree }: IProps) => {
  const defaultEnableParents =
    process.env.REACT_APP_NODE_ENV === "development" ? true : false;
  const [enableParents, setEnableParents] =
    useState<boolean>(defaultEnableParents);
  const [availableCities, setAvailableCities] = useState<string[]>([]);

  const states: string[] = [
    ...new Set(
      statesData.departamentos.map(
        (departamento) => departamento.provincia.nombre
      )
    ),
  ];

  const handleParentsAmmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    const parentArray: Person[] = familyTree.parents
      ? [...familyTree.parents]
      : [];
    const requiredAmmount = Number(e.target.value);
    console.log("🚀 ~ requiredAmmount:", requiredAmmount);
    const existingAmmount = familyTree.parents?.length ?? 0;
    console.log("🚀 ~ existingAmmount:", existingAmmount);
    const existingEnabledAmmount =
      familyTree.parents?.filter((parent) => parent.enabled).length ?? 0;
    const isADisabledParent = existingAmmount > existingEnabledAmmount;

    const handleSetParentArray = (parentArray: Person[]) => {
      setFamilyTree({
        ...familyTree,
        parents: [...parentArray],
      });
    };
    let parentToEnable, parentToDisable;
    switch (true) {
      case requiredAmmount === 0:
        // Disable all parents
        handleSetParentArray(
          parentArray.map((parent) => ({ ...parent, enabled: false }))
        );
        break;
      case existingAmmount === 0 ||
        (requiredAmmount > existingAmmount && !isADisabledParent):
        // Create a new blank parent
        parentArray.push({ dateOfDeath: undefined, enabled: true });
        handleSetParentArray(parentArray);
        break;
      case requiredAmmount > existingEnabledAmmount && isADisabledParent:
        // Simply enable first disabled parent
        parentToEnable = parentArray.findIndex(
          (parent) => parent.enabled === false
        );
        parentArray[parentToEnable].enabled = true;
        handleSetParentArray(parentArray);
        break;
      case requiredAmmount < existingEnabledAmmount:
        // Disable last currently enabled parent
        parentToDisable =
          parentArray.filter((parent) => parent.enabled === true).length - 1;
        parentArray[parentToDisable].enabled = false;
        handleSetParentArray(parentArray);
        break;
    }
  };
  const handleDateOfDeath = (
    e: React.ChangeEvent<HTMLInputElement>,
    parentNumber: number
  ) => {
    const familyTreeCopy = { ...familyTree };
    if (familyTreeCopy.parents && familyTreeCopy.parents[parentNumber]) {
      // If the value sent is not a date, then we set the dateOfDeath to undefined
      familyTreeCopy.parents[parentNumber].dateOfDeath = isNaN(
        Date.parse(e.target.value)
      )
        ? undefined
        : e.target.value;

      setFamilyTree({ ...familyTreeCopy });
    }
  };
  const handleParentData = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    parentIndex: number
  ) => {
    const property = e.target.name as keyof Person;

    // Literally this value could be any type
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let value: any = e.target.value;

    // Dynamically handle type conversions if needed
    if (property === "enabled" || property === "mainDeseased") {
      value = e.target.value === "true"; // Assuming values are passed as "true" or "false" strings
    } else if (property === "dateOfBirth" || property === "dateOfDeath") {
      // Date might need to be undefined if it's an invalid date or empty
      value = value ? new Date(value).toISOString() : undefined;
    }

    const familyTreeCopy = { ...familyTree };
    if (familyTreeCopy.parents && familyTreeCopy.parents[parentIndex]) {
      familyTreeCopy.parents[parentIndex][property] = value;
    }
    setFamilyTree({
      ...familyTreeCopy,
    });
    if (property === "state") {
      setAvailableCities(
        statesData.departamentos
          .filter((departamentos) => {
            if (
              departamentos.provincia.nombre === value &&
              departamentos.provincia.nombre !==
                "Ciudad Autónoma de Buenos Aires"
            ) {
              return departamentos.nombre;
            }
          })
          .map((departamentos) => departamentos.nombre)
      );
    }
    console.log("🚀 ~ familyTree:", familyTree);
  };

  return (
    <>
      <div style={{ minHeight: "120px" }}>
        <div className="card card-body">
          <div className="mb-3">
            <h4>4) Padres</h4>
            <p>
              Si el fallecido no tiene descendencia viva, entonces le heredan
              sus padres. Si sus padres ya murieron, igual debe completar sus
              datos para que pueda heredar el hermano del fallecido.
            </p>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                defaultChecked={enableParents}
                type="checkbox"
                onChange={() => {
                  setEnableParents(!enableParents);
                }}
                role="switch"
                name="enableParents"
                id="enableParents"
              />
            </div>
            <label htmlFor="parentsAmmount" className="form-label">
              Cantidad de padres o madres reconocidos por ley (incluye
              adoptivos):
            </label>
            <input
              type="number"
              min={0}
              max={2}
              placeholder="0"
              className="form-control"
              onChange={(e) => handleParentsAmmount(e)}
              id="parentsAmmount"
              name="parentsAmmount"
              aria-describedby="Cantidad de hijos del fallecido"
            />
          </div>
        </div>
        {enableParents &&
          familyTree.parents
            ?.filter((parent: Person) => parent.enabled)
            .map((parent: Person, index: number) => {
              return (
                <div
                  className="card card-body"
                  key={`parentN${index}`}
                  id={`parentN${index}`}
                >
                  <div className="mb-3">
                    <h4>Padre o Madre </h4>

                    <label htmlFor="name" className="form-label">
                      Nombre
                    </label>
                    <input
                      onChange={(e) => {
                        handleParentData(e, index);
                      }}
                      type="text"
                      className="form-control"
                      value={familyTree.parents?.[index].name || ""}
                      id={"name"}
                      name={"name"}
                      aria-describedby="Nombre del fallecido"
                    />
                    <label htmlFor="lastname" className="form-label">
                      Apellido
                    </label>
                    <input
                      onChange={(e) => {
                        handleParentData(e, index);
                      }}
                      type="text"
                      className="form-control"
                      value={familyTree.parents?.[index].lastname || ""}
                      id={"lastname"}
                      name={"lastname"}
                      aria-describedby="Apellido del fallecido"
                    />

                    <label htmlFor="name" className="form-label">
                      DNI
                    </label>
                    <input
                      onChange={(e) => {
                        handleParentData(e, index);
                      }}
                      type="text"
                      className="form-control"
                      value={familyTree.parents?.[index].dni || ""}
                      id={"dni"}
                      name={"dni"}
                      aria-describedby="Número de Dni del hijo/a"
                    />

                    <h6>Domicilio actual:</h6>
                    <p>
                      Nos referimos al lugar donde usualmente vive, es decir,
                      donde tiene su hogar.
                    </p>

                    <label htmlFor="state" className="form-label">
                      Provincia
                    </label>
                    <select
                      className="form-control"
                      onChange={(e) => {
                        handleParentData(e, index);
                      }}
                      name="state"
                      id="state"
                    >
                      {states.map((state, index) => (
                        <option key={index} value={state}>
                          {state}
                        </option>
                      ))}
                    </select>

                    <label htmlFor="city" className="form-label">
                      Ciudad
                    </label>
                    <select
                      className="form-control"
                      onChange={(e) => {
                        handleParentData(e, index);
                      }}
                      name="city"
                      id="city"
                    >
                      {availableCities.map((city, index) => (
                        <option key={index} value={city}>
                          {city}
                        </option>
                      ))}
                    </select>

                    <label htmlFor="street" className="form-label">
                      Calle, número y departamento
                    </label>
                    <input
                      onChange={(e) => {
                        handleParentData(e, index);
                      }}
                      type="text"
                      className="form-control"
                      value={familyTree.parents?.[index].street || ""}
                      id={"street"}
                      name={"street"}
                      aria-describedby="Ej: Balcarce 670, 9A"
                    />

                    <p>
                      Si este hijo/a ya falleció, indique fecha de
                      fallecimiento.
                    </p>
                    <label htmlFor="name" className="form-label">
                      <strong>Opcional</strong> - Fecha de fallecimiento:
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      value={parent.dateOfDeath}
                      onChange={(e) => handleDateOfDeath(e, index)}
                      id="deathDate"
                      name="deathDate"
                      aria-describedby="Fecha de fallecimiento del hijo"
                    />
                  </div>
                </div>
              );
            })}
      </div>
    </>
  );
};
export default Parents;
