import React from "react";
import { GoogleAuthProvider, getAuth, signInWithPopup } from "firebase/auth";
import appUserService from "../../service/appUser";
import { useToast } from "../toastContext/toastContext";
import { BeResponse } from "src/types/general";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate();
  const { addToast } = useToast();
  const signInWithGoogle = async () => {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      const idToken = await user.getIdToken(); // Retrieve the ID token directly with await

      // Use the ID token to create the user on your backend
      const loginResponse = await appUserService.createLoginUser(
        {
          name: user.displayName || "",
          photoUrl: user.photoURL,
          email: user.email,
          firebaseId: user.uid,
          roles: ["user"],
        },
        idToken
      );
      if (loginResponse?.data?.content?.token) {
        //Handle token
        localStorage.setItem("token", loginResponse.data.content.token);
        localStorage.setItem(
          "user",
          JSON.stringify(loginResponse.data.content.user)
        );
        addToast(
          `Bienvenido/a ${loginResponse.data.content.user.name ?? "!"}`,
          "Inicio de sesión correcta",
          "success"
        );
        navigate("/stepper");
      } else {
        //Handle token
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        throw {
          publicTitle: "Error al iniciar sesión",
          publicMsg: "Por favor, intente iniciar sesión nuevamente.",
        };
      }
    } catch (error) {
      //Handle token
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      const { publicTitle, publicMsg } = error as BeResponse;
      addToast(publicMsg, publicTitle, "danger");
    }
  };

  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ minHeight: "100vh" }}
    >
      <div>
        <div>
          <h1>Logo</h1>
        </div>
        <div id="signInOptions">
          <button
            className="btn btn-primary p-2 btn-lg"
            onClick={signInWithGoogle}
          >
            <i className="fa-brands fa-google"></i> Iniciar sesión con Google
          </button>
        </div>
      </div>
    </div>
  );
};

export default Login;
