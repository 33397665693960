import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Login from "./components/login/login";
import Navbar from "./components/navbar/navbar";
import React from "react";
import { ToastProvider } from "./components/toastContext/toastContext";
import ProtectedRoute from "./configs/protectedRoutes";

import Stepper from "./components/view/stepper/stepper";
import Dashboard from "./components/view/dashboard/dashboard";

function App() {
  return (
    <Router>
      <ToastProvider>
        <div className="App">
          <header>
            <Navbar />
          </header>
        </div>
        <Routes>
          {/* Public routes */}
          <Route path="/login" element={<Login />} />
          <Route element={<ProtectedRoute redirectPath="/login" />}>
            {/* Protected routes */}
            <Route path="/stepper" element={<Stepper />} />
            <Route path="/dashboard" element={<Dashboard />} />
          </Route>
        </Routes>
      </ToastProvider>
    </Router>
  );
}

export default App;
