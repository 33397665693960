import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAgpa-5hDMeHoyRffRPAueA2FWbQTEuJjA",
  authDomain: "sucesion-express.firebaseapp.com",
  projectId: "sucesion-express",
  storageBucket: "sucesion-express.appspot.com",
  messagingSenderId: "128958107440",
  appId: "1:128958107440:web:e2b2a95c5b1c69415ac2f5",
  measurementId: "G-TFFERF4N7K"
};

const app = initializeApp(firebaseConfig);
export default app

// Only initialize analytics if it's being used and environment supports it
if (typeof window !== 'undefined') getAnalytics(app);