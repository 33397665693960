import axios from 'axios';

const baseURL = process.env.REACT_APP_NODE_ENV === 'development' ? process.env.REACT_APP_LOCAL_BACKEND_URL : process.env.REACT_APP_BACKEND_URL;
const backendClient = axios.create({
    baseURL
});

backendClient.interceptors.request.use(
    config => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    error => {
        if (process.env.REACT_APP_ENVIRONMENT === 'development') {
            console.error('Error during HTTP request:', error);
        }
        return Promise.reject(error);
    }
);
export default backendClient;