import { useAuth } from "../../context/authContext";
import { getAuth, signOut } from "firebase/auth";
import React from "react";
import { Link } from "react-router-dom";
import "./navbar.scss";
import logo from "../../static/logo-h.png";

const Navbar = () => {
  const { currentUser } = useAuth();
  const auth = getAuth();

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        console.log("User signed out successfully!");
      })
      .catch((error) => {
        console.error("Sign out error:", error);
      });
  };

  return (
    <nav
      className="navbar navbar-expand-md navbar-light bg-light fixed-top"
      aria-label="Fourth navbar example"
    >
      <div className="container-fluid d-flex justify-content-between">
        <div className="navbar-brand">
          <img
            src={logo}
            alt="Sucesión Express Logo"
            style={{ width: "30%" }}
          />
        </div>
        <div>
          {currentUser ? (
            <div className="d-flex justify-content-end">
              <span className="btn btn-primary p-1 mx-1">
                <Link to="/stepper" className="login-btn">
                  Iniciar sucesión
                </Link>
              </span>
              <span className="nav-item dropdown">
                <button
                  className="nav-link dropdown-toggle mt-1 mx-1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {currentUser.displayName || "User"}
                </button>
                <ul className="dropdown-menu bg-light text-dark">
                  <li>
                    <Link to="/dashboard" className="dropdown-item">
                      Panel de control
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/login"
                      className="dropdown-item"
                      onClick={handleLogout}
                    >
                      Cerrar sesión
                    </Link>
                  </li>
                </ul>
              </span>
            </div>
          ) : (
            <Link to="/login" className="nav-link active" aria-current="page">
              Iniciar sesión
            </Link>
          )}
        </div>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarsExample04"
          aria-controls="navbarsExample04"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
      </div>
    </nav>
  );
};

export default Navbar;
